<template>
    
    <div class="nav-container" :class="{open: open}"  ref="nav-con"  v-touch:swipe.top="() => {$refs.remote.expand() }">
        <Remote  ref="remote"/>   
        
        <div class="nav" ref="nav">
            <Transition name="fade">
                <div class="minidid"  v-if="minidid">
                    <div v-for="(d,i) in dids" class="dids" :key="i" :data-id="d.id" :class="d.id">
                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none"> <path d="M6 17L3 17L3 4L21 4L21 17L18 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M8.62188 19.0672L11.5008 14.7488C11.7383 14.3926 12.2617 14.3926 12.4992 14.7488L15.3781 19.0672C15.6439 19.4659 15.3581 20 14.8789 20H9.12111C8.64189 20 8.35606 19.4659 8.62188 19.0672Z" stroke="currentColor" stroke-width="1.5" /> </svg>
                        {{ d.name }}
                    </div>
                </div>
            </Transition>

            <div v-ripple v-for="(s,i) in sections"  :class="{active: isActive(s.name), hide: minidid}" :key="i" class="item" :to="s.url" 
                    @click="$event => goToLink(s.url)"
                    v-touch:touchhold="$event => startMiniDid($event, s.name)"
                    v-touch:moving="updateMiniDid"
                    v-touch:end="$event => endMiniDid($event)"
                >
                <div v-html="s.icon"></div>
            </div>
        </div>
    </div>
</template>

<script>

const Remote = () =>  import('@/components/Remote')

export default {
  name: 'Nav',
  

  components: {
    Remote
  },

  data: function(){
    return {
        open: true,
        minidid: false,
        dids: [],
        didsLoading: true,
        sections: [
            {
                name: 'channels',
                id: 'Home',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"> <path d="M9 21H7C4.79086 21 3 19.2091 3 17V10.7076C3 9.30887 3.73061 8.01175 4.92679 7.28679L9.92679 4.25649C11.2011 3.48421 12.7989 3.48421 14.0732 4.25649L19.0732 7.28679C20.2694 8.01175 21 9.30887 21 10.7076V17C21 19.2091 19.2091 21 17 21H15M9 21V17C9 15.3431 10.3431 14 12 14V14C13.6569 14 15 15.3431 15 17V21M9 21H15" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>`,
                url: '/channels',
            },
            {
                name: 'search',
                id: 'Search',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"> <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>`,
                url: '/search',
            },
            {
                name: 'od',
                id: 'Saved',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"> <path d="M6 17L3 17L3 4L21 4L21 17L18 17" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M8.62188 19.0672L11.5008 14.7488C11.7383 14.3926 12.2617 14.3926 12.4992 14.7488L15.3781 19.0672C15.6439 19.4659 15.3581 20 14.8789 20H9.12111C8.64189 20 8.35606 19.4659 8.62188 19.0672Z" stroke="white" stroke-width="1.5"/> </svg>`,
                url: '/vod',
            },
            {
                name: 'devices',
                id: 'Devices',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"> <path d="M6 18.01L6.01 17.9989" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M6 6.01L6.01 5.99889" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 9.4V2.6C2 2.26863 2.26863 2 2.6 2H21.4C21.7314 2 22 2.26863 22 2.6V9.4C22 9.73137 21.7314 10 21.4 10H2.6C2.26863 10 2 9.73137 2 9.4Z" stroke="white" stroke-width="1.5"/> <path d="M2 21.4V14.6C2 14.2686 2.26863 14 2.6 14H21.4C21.7314 14 22 14.2686 22 14.6V21.4C22 21.7314 21.7314 22 21.4 22H2.6C2.26863 22 2 21.7314 2 21.4Z" stroke="white" stroke-width="1.5"/> </svg>`,
                url: '/devices',
            }
        ]
    }
  },
  
  methods: {
    goToLink(url){
        this.$router.push({path: url})
    },
    fetchDids(){
        this.didsLoading = true
        fetch(process.env.VUE_APP_SERVER +  `/dids?qb=${window.qb}`).then(response => response.json()).then((data) => { 
            this.dids = data.dids 
            this.didsLoading = false
        })
    },

    startMiniDid(e, name){
        if(name === 'devices'){
            if(this.dids.length  === 0){this.fetchDids()}
            if(navigator && navigator.vibrate) { navigator.vibrate(50) }
            e.preventDefault()
            e.stopPropagation()
            this.minidid = true;
            this.$nextTick(() => {
                if(window.did && document.querySelector(`.dids.${window.did}`)){
                    document.querySelector(`.dids.${window.did}`).classList.add('sel')
                }
            })
        }
    },

    endMiniDid(e){
        if(!this.minidid){ return }
        e.preventDefault()
        e.stopPropagation()
        const newDid = document.querySelector('.minidid .dids.sel').getAttribute('data-id')
        this.minidid = false;
        this.$root.$emit('newDid', newDid)
    },

    updateMiniDid(event){
        if(!this.minidid){return false}
        const touchX = event.changedTouches[0].clientX; 
        const didDivs = document.querySelectorAll('.minidid .dids'); 

        let nearestDiv = null;
        let minDistance = Infinity;

        for (const div of didDivs) {
            const rect = div.getBoundingClientRect(); // Get the position and dimensions of the <div>
            const divCenterX = rect.left + rect.width / 2; // Calculate the X-coordinate of the center of the <div>
            const distance = Math.abs(divCenterX - touchX); // Calculate the distance between the touch and the <div>
            
            // Check if the current <div> is closer to the touch than the previous closest <div>
            if (distance < minDistance) {
                minDistance = distance;
                nearestDiv = div;
            }
        }

        didDivs.forEach((div) => {
            div.classList.remove('sel');
        });

        if (nearestDiv) {
            nearestDiv.classList.add('sel');
        }

    },
    isActive(s){
        if(this.$route && this.$route.name){
            return this.$route.name.toLowerCase().includes(s.toLowerCase())
        }
        return false
    }
  
  },

  beforeDestroy: function(){

  },

  mounted: function(){

  }
}
</script>

<style  lang="scss">
.nav-container{
    // overflow: hidden;
}

.remoteOpen .nav-container{
    overflow: visible;
    // bottom: 1vh;
    background-color: rgba(252, 252, 252, 0.15);
    // width: 98vw;
    // left: 1vw;
    // border-bottom-right-radius: 35px;
    // border-bottom-left-radius: 35px;
}

.nav-container{
    height: auto;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    position: fixed;
    bottom: 0;
    width: 100vw;
    left: 0;
    box-shadow: -4px -1px 15px 5px rgba(0, 0, 0, 0.5);
    z-index: 99;
    &.open{ 
        backdrop-filter: blur(15px);
    }
    
    .nav{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 15px 0;
        margin: 0 auto;
        color: white;
        overflow: hidden;
        svg{
            width: 20px;
            pointer-events: none;
            stroke: white;
            color: white;
        }
        .hide{
            opacity: 0;
        }

        .active{
            position: relative;
          
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: -15px;
                left: 0;
                opacity: 0.7;
                background-color: white;
            }
        }

        .item{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: white;
            border-radius: 50%;
            p{
                padding: 0;
                padding-top: 3px;
                font-size: 8px;
                font-weight: bold;
                text-transform: lowercase;
                margin: 0;
                color: white;
            }
        }
    }
   
}
.minidid{
    position: fixed;
    right: 0;
    bottom: 0px;
    display: flex;
    width: 100vw;
    padding: 10px 0;
    padding-right: 20px;
    justify-content: flex-end;
    z-index: 9999;
    pointer-events: none;
    > div{
        padding: 10px 7.5px;
        pointer-events: none;
        margin-left:  10px;
        backdrop-filter: blur(20px);
        background-color: rgba(black, 0.5);
        border-radius: 4px;
        font-size: 8px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        svg{
            max-width: 10px;
            padding-right: 5px;
        }
        &.sel{
            background-color: rgba(white, 0.85);
            color: black;
            z-index: 999999;
            transform: scale(1.15) translateY(-5px);
            transform-origin: center;
        }
    }
}
</style>
